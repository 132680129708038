import {RouterModule, Routes, UrlSegment} from '@angular/router';
import { AboutComponent } from './main/pages/about/about.component';
import { HomeComponent } from './main/home/home/home.component';
import { LocateUsComponent } from './main/pages/locate-us/locate-us.component';
import { PricingComponent } from './main/pages/pricing/pricing.component';
import { ServicesComponent } from './main/pages/services/services.component';
import { LoginComponent } from './main/pages/login/login.component';
import { SignUpComponent } from './main/pages/sign-up/sign-up.component'
import { MainBlogsComponent } from './blog/main-blogs/main-blogs.component';
import { FranchiseComponent } from './main/pages/franchise/franchise.component';
import { MediaComponent } from './main/pages/media/media.component';
import { PrivacyPolicyComponent } from './main/pages/privacy-policy/privacy-policy.component';
import { CareerComponent } from './main/pages/career/career.component';
import { TermsAndConditionsComponent } from './main/pages/terms-and-conditions/terms-and-conditions.component';
import { CovidComponent } from './main/pages/covid/covid.component';
import { BuildYourOrderComponent } from './main/build-order/build-your-order/build-your-order.component';
import { SchedulePickupComponent } from './main/build-order/schedule-pickup/schedule-pickup.component';
import { ChooseAddressComponent } from './main/build-order/choose-address/choose-address.component';
import { ConfirmOrderComponent } from './main/build-order/confirm-order/confirm-order.component';
import { BlogDetailComponent } from './blog/blog-detail/blog-detail.component'
import { StoreDetailComponent } from './main/pages/store-detail/store-detail.component';
import { UserProfileComponent } from './main/pages/user-profile/user-profile.component';
import { CitiesComponent } from './main/pages/cities/cities.component';
import {NgModule} from '@angular/core';
import { UserAddressesComponent } from './main/pages/user-addresses/user-addresses.component';
import { OrderHistoryComponent } from './main/pages/order-history/order-history.component';
import { ProfileSettingsComponent } from './main/pages/profile-settings/profile-settings.component';
import { FranchiseV2Component } from './main/pages/franchise-v2/franchise-v2.component';
import { LoginPageComponent } from './main/auth/login-page/login-page.component';
import { LoginOtpVerificationComponent } from './main/auth/login-otp-verification/login-otp-verification.component';
import { OtpVerificationSuccessComponent } from './main/auth/otp-verification-success/otp-verification-success.component';
import { SignupPageComponent } from './main/auth/signup-page/signup-page.component';
import { OrderConfirmedSuccessComponent } from './main/pages/order-confirmed-success/order-confirmed-success.component';
import { FranchiseAppliedSuccessComponent } from './main/pages/franchise-applied-success/franchise-applied-success.component';
import { DryCleaningComponent } from './main/pages/dry-cleaning/dry-cleaning.component';
import { PremiumLaundryComponent } from './main/pages/premium-laundry/premium-laundry.component';
import { SteamIroningComponent } from './main/pages/steam-ironing/steam-ironing.component';
import { BagCleaningComponent } from './main/pages/bag-cleaning/bag-cleaning.component';
import { CarpetCleaningComponent } from './main/pages/carpet-cleaning/carpet-cleaning.component';
import { CurtainCleaningComponent } from './main/pages/curtain-cleaning/curtain-cleaning.component';
import { ShoeCleaningComponent } from './main/pages/shoe-cleaning/shoe-cleaning.component';
import { SofaCleaningComponent } from './main/pages/sofa-cleaning/sofa-cleaning.component';
import { LaundryComponent } from './main/pages/laundry/laundry.component';


const routes: Routes = [
  { path:'',component:HomeComponent},
  { path:'about-us',component:AboutComponent},
  { path:'laundry-drycleaning-price-list',component:PricingComponent},
  { path:'uclean-store-near-you',component:LocateUsComponent},
  { path:'locate_us',component:LocateUsComponent},
  {
    matcher: (url) => {
      if (url.length === 1 && url[0].path.includes("laundry-and-dry-cleaners-in-")) {
        return {
          consumed: url,
          posParams: {
            url: new UrlSegment(url[0].path.substr(url[0].path.indexOf('laundry-and-dry-cleaners-in-')+28), {})
          }
        };
      }      return null;
    },
    component: StoreDetailComponent
  },
  { path:'apply-for-franchise1',component:FranchiseComponent},
  { path:'apply-for-franchise',component:FranchiseV2Component},
  { path:'apply-for-franchise/success',component: FranchiseAppliedSuccessComponent},
  { path:'careers',component:CareerComponent},
  { path:'privacy-policy',component:PrivacyPolicyComponent},
  { path:'terms-and-conditions',component:TermsAndConditionsComponent},
  { path:'covid-19',component:CovidComponent},

  // auth pages
  { path:'ui/LogIn',component:LoginComponent},
  { path:'ui/SignUp',component:SignUpComponent},

  { path:'media',component:MediaComponent},
  { path:'stores-by-cities/:id/:name',component:CitiesComponent},

  // blog pages
  { path:'blogs',component:MainBlogsComponent},
  { path:'blog/:slug',component:BlogDetailComponent},

  // order pages
  { path:'build-your-order',component:BuildYourOrderComponent},
  { path:'schedule-pick-up',component:SchedulePickupComponent},
  { path:'choose-address',component:ChooseAddressComponent},
  { path:'confirm-order',component:ConfirmOrderComponent},
  { path:'order-booked',component: OrderConfirmedSuccessComponent},
  // { path:'order-booked',component:OrderConfrimedComponent},

  // Service pages
  { path:'services',component:ServicesComponent},
  { path: 'services/DryCleaning', component: DryCleaningComponent },
  { path: 'services/Laundry', component: LaundryComponent },
  { path: 'services/PremiumLaundry', component: PremiumLaundryComponent },
  { path: 'services/SteamIroning', component: SteamIroningComponent },
  { path: 'services/ShoeCleaning', component: ShoeCleaningComponent },
  { path: 'services/BagCleaning', component: BagCleaningComponent },
  { path: 'services/CurtainCleaning', component: CurtainCleaningComponent },
  { path: 'services/SofaCleaning', component: SofaCleaningComponent },
  { path: 'services/CarpetCleaning', component: CarpetCleaningComponent },

  // Profile pages
  { path:'profile',component:UserProfileComponent},
  { path:'profile/address-book',component:UserAddressesComponent},
  { path:'profile/order-history',component:OrderHistoryComponent},
  { path:'profile/notification-setting',component:ProfileSettingsComponent},
  
  // New Login Screens
  { path:'login-page',component: LoginPageComponent},
  { path:'signup-page',component: SignupPageComponent},
  { path:'otp-verification',component: LoginOtpVerificationComponent},
  { path:'otp-verification-success',component: OtpVerificationSuccessComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
